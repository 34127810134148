var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });

// src/duration/duration.constant.ts
var ONE_SECOND_IN_MS = 1e3;
var ONE_MINUTE_IN_MS = ONE_SECOND_IN_MS * 60;
var QUARTER_IN_MS = 15 * ONE_MINUTE_IN_MS;
var ONE_HOUR_IN_MS = ONE_MINUTE_IN_MS * 60;
var TWO_HOURS_IN_MS = 2 * ONE_HOUR_IN_MS;
var ONE_DAY_IN_MS = ONE_HOUR_IN_MS * 24;
var ONE_YEAR_IN_MS = ONE_DAY_IN_MS * 365;
var THIRTY_SECONDS_IN_MS = 30 * ONE_SECOND_IN_MS;

// src/duration/duration.ts
var Duration = class _Duration {
  constructor(milliseconds) {
    this.milliseconds = milliseconds;
  }
  static {
    __name(this, "Duration");
  }
  static ms(milliseconds) {
    return new _Duration(milliseconds);
  }
  static hours(hours) {
    return new _Duration(hours * ONE_HOUR_IN_MS);
  }
  get inSeconds() {
    return Math.ceil(this.milliseconds / ONE_SECOND_IN_MS);
  }
  get inMinutes() {
    return Math.ceil(this.milliseconds / ONE_MINUTE_IN_MS);
  }
  get inHours() {
    return Math.ceil(this.milliseconds / ONE_HOUR_IN_MS);
  }
  get inMilliseconds() {
    return this.milliseconds;
  }
  canBeDividedBy(divider) {
    return this.milliseconds % divider.milliseconds === 0;
  }
  toString() {
    const hours = Math.floor(this.milliseconds / ONE_HOUR_IN_MS);
    const minutes = Math.floor(
      this.milliseconds % ONE_HOUR_IN_MS / ONE_MINUTE_IN_MS
    );
    const formattedHours = hours.toString();
    const formattedMinutes = minutes.toString().padStart(2, "0");
    return `${formattedHours}h${formattedMinutes}`;
  }
};

// src/period/period.ts
var END_BEFORE_START_ERROR_MESSAGE = "La date de fin doit \xEAtre apr\xE8s la date de d\xE9but";
var EndBeforeStart = class extends Error {
  static {
    __name(this, "EndBeforeStart");
  }
  constructor() {
    super(END_BEFORE_START_ERROR_MESSAGE);
  }
};
var Period = class _Period {
  static {
    __name(this, "Period");
  }
  start;
  end;
  constructor(start, end) {
    this.start = start;
    this.end = end;
  }
  get hasDuration() {
    return this.start.getTime() !== this.end.getTime();
  }
  static init({ start, end }) {
    if (_Period.isEndBeforeStart({ start, end })) {
      throw new EndBeforeStart();
    }
    return new _Period(start, end);
  }
  static isValid(period) {
    return this.errors(period).length === 0;
  }
  static errors(period) {
    return _Period.isEndBeforeStart(period) ? [END_BEFORE_START_ERROR_MESSAGE] : [];
  }
  static isEndBeforeStart({ start, end }) {
    return end.getTime() < start.getTime();
  }
  isOverlapping(otherPeriod) {
    return this.start.getTime() < otherPeriod.end.getTime() && this.end.getTime() > otherPeriod.start.getTime();
  }
  includes(otherPeriod) {
    return this.start.getTime() <= otherPeriod.start.getTime() && this.end.getTime() >= otherPeriod.end.getTime();
  }
  isIncluding(date) {
    const happenAfterStart = date.getTime() >= this.start.getTime();
    const happenBeforeEnd = date.getTime() < this.end.getTime();
    return happenAfterStart && happenBeforeEnd;
  }
  isMergableWith(otherPeriod) {
    return this.start.getTime() <= otherPeriod.end.getTime() && this.end.getTime() >= otherPeriod.start.getTime();
  }
  mergeWith(otherPeriod) {
    const start = new Date(
      Math.min(otherPeriod.start.getTime(), this.start.getTime())
    );
    const end = new Date(
      Math.max(otherPeriod.end.getTime(), this.end.getTime())
    );
    return new _Period(start, end);
  }
  splitOverlapping(otherPeriod) {
    const [firstStart, firstEnd, secondStart, secondEnd] = [
      this.start,
      this.end,
      otherPeriod.start,
      otherPeriod.end
    ].sort((a, b) => a.getTime() - b.getTime());
    const firstPeriod = _Period.init({ start: firstStart, end: firstEnd });
    const secondPeriod = _Period.init({ start: firstEnd, end: secondStart });
    const thirdPeriod = _Period.init({ start: secondStart, end: secondEnd });
    return [firstPeriod, secondPeriod, thirdPeriod].filter(
      (period) => period.hasDuration
    );
  }
  substract(otherPeriod) {
    const pastPeriod = _Period.init({
      start: this.start,
      end: otherPeriod.start
    });
    const futurPeriod = _Period.init({
      start: otherPeriod.end,
      end: this.end
    });
    return [pastPeriod, futurPeriod].filter((period) => period.hasDuration);
  }
  remove(otherPeriod) {
    if (!otherPeriod.isOverlapping(this)) {
      return [this];
    }
    return this.mergeWith(otherPeriod).substract(otherPeriod);
  }
  equals(otherPeriod) {
    const startAtSameTime = this.start.getTime() === otherPeriod.start.getTime();
    const endAtSameTime = this.end.getTime() === otherPeriod.end.getTime();
    return startAtSameTime && endAtSameTime;
  }
  static mergeContiguous(periods) {
    const sortedPeriods = this.sort(periods);
    return sortedPeriods.reduce(
      (mergedPeriods, currentPeriod) => {
        const lastMergedPeriod = mergedPeriods.at(mergedPeriods.length - 1);
        if (!lastMergedPeriod) return [currentPeriod];
        const isMergeable = lastMergedPeriod.isMergableWith(currentPeriod);
        if (isMergeable) {
          const mergedPeriod = lastMergedPeriod.mergeWith(currentPeriod);
          return [...mergedPeriods.slice(0, -1), mergedPeriod];
        }
        return [...mergedPeriods, currentPeriod];
      },
      []
    );
  }
  splitWithIntervalInMs(intervalInMs) {
    const periodCount = Math.ceil(
      (this.end.getTime() - this.start.getTime()) / intervalInMs
    );
    return Array.from({ length: periodCount }, (_, index) => {
      const start = new Date(this.start.getTime() + index * intervalInMs);
      const end = new Date(start.getTime() + intervalInMs);
      return _Period.init({ start, end: end < this.end ? end : this.end });
    });
  }
  toString() {
    const start = this.formatDate(this.start);
    const end = this.formatDate(this.end);
    return `${start} - ${end}`;
  }
  formatDate(date) {
    const displayOptions = {
      dateStyle: "long",
      timeStyle: "short"
    };
    return new Intl.DateTimeFormat("fr", displayOptions).format(date);
  }
  static sort(periods) {
    return periods.sort(
      (a, b) => a.start.getTime() - b.start.getTime() || a.end.getTime() - b.end.getTime()
    );
  }
  get duration() {
    const startTimestamp = this.start.getTime();
    const endTimestamp = this.end.getTime();
    return Duration.ms(endTimestamp - startTimestamp);
  }
  get id() {
    const startMinutes = Duration.ms(this.start.getTime()).inMinutes;
    const endMinutes = Duration.ms(this.end.getTime()).inMinutes;
    return `${startMinutes}-${endMinutes}`;
  }
};

// src/date/date.utils.ts
function formatDateWithMinutes(date) {
  const displayOptions = {
    ...PARIS_TIMEZONE,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit"
  };
  return new Intl.DateTimeFormat("fr", displayOptions).format(new Date(date));
}
__name(formatDateWithMinutes, "formatDateWithMinutes");
function formatDate(date) {
  const displayOptions = {
    ...PARIS_TIMEZONE,
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
  };
  return new Intl.DateTimeFormat("fr", displayOptions).format(new Date(date));
}
__name(formatDate, "formatDate");
function formatMonthWithYear(date) {
  const displayOptions = {
    year: "numeric",
    month: "long"
  };
  return new Intl.DateTimeFormat("fr", displayOptions).format(new Date(date));
}
__name(formatMonthWithYear, "formatMonthWithYear");
function formatLocalDateTime(date) {
  const year = date.getFullYear();
  const month = getTwoDigitsNumber(date.getMonth() + 1);
  const day = getTwoDigitsNumber(date.getDate());
  const hours = getTwoDigitsNumber(date.getHours());
  const minutes = getTwoDigitsNumber(date.getMinutes());
  return `${year}-${month}-${day}T${hours}:${minutes}`;
}
__name(formatLocalDateTime, "formatLocalDateTime");
function formatLocalDate(date) {
  const year = date.getFullYear();
  const month = getTwoDigitsNumber(date.getMonth() + 1);
  const day = getTwoDigitsNumber(date.getDate());
  return `${year}-${month}-${day}`;
}
__name(formatLocalDate, "formatLocalDate");
function getTwoDigitsNumber(number) {
  return number < 10 ? "0" + number : number.toString();
}
__name(getTwoDigitsNumber, "getTwoDigitsNumber");
function getHourDiff(start, end) {
  const diff = end.getTime() - start.getTime();
  return diff / ONE_HOUR_IN_MS;
}
__name(getHourDiff, "getHourDiff");
function roundMinutes(date, round) {
  if (!date) return null;
  const minutes = date.getMinutes();
  if (minutes % round === 0) return date;
  const minutesRounded = Math.round(minutes / round) * round;
  date.setMinutes(minutesRounded);
  return date;
}
__name(roundMinutes, "roundMinutes");
function formatDateWithExplicitMonth(date) {
  const displayOptions = {
    ...PARIS_TIMEZONE,
    year: "numeric",
    month: "long"
  };
  return new Intl.DateTimeFormat("fr", displayOptions).format(new Date(date));
}
__name(formatDateWithExplicitMonth, "formatDateWithExplicitMonth");
function formatDateWithExplicitMonthAndDay(date) {
  const displayOptions = {
    ...PARIS_TIMEZONE,
    year: "numeric",
    month: "long",
    day: "numeric"
  };
  return new Intl.DateTimeFormat("fr", displayOptions).format(new Date(date));
}
__name(formatDateWithExplicitMonthAndDay, "formatDateWithExplicitMonthAndDay");
function formatDateDayName(date) {
  return new Date(date).toLocaleDateString("fr-FR", {
    ...PARIS_TIMEZONE,
    weekday: "short"
  });
}
__name(formatDateDayName, "formatDateDayName");
function formatDateDayNumber(date) {
  return new Date(date).toLocaleDateString("fr-FR", {
    ...PARIS_TIMEZONE,
    day: "numeric"
  });
}
__name(formatDateDayNumber, "formatDateDayNumber");
function computeTomorrowDate(date) {
  return new Date(date.getTime() + ONE_DAY_IN_MS);
}
__name(computeTomorrowDate, "computeTomorrowDate");
function formatDateToHumanReadable(date) {
  const displayOptions = {
    ...PARIS_TIMEZONE,
    dateStyle: "long",
    timeStyle: "short"
  };
  return new Intl.DateTimeFormat("fr", displayOptions).format(new Date(date));
}
__name(formatDateToHumanReadable, "formatDateToHumanReadable");
function formatDateWithHoursAndMinutesOnly(date) {
  const displayOptions = {
    ...PARIS_TIMEZONE,
    hour: "2-digit",
    minute: "2-digit"
  };
  return new Intl.DateTimeFormat("fr", displayOptions).format(new Date(date));
}
__name(formatDateWithHoursAndMinutesOnly, "formatDateWithHoursAndMinutesOnly");
function isSameDay(date1, date2) {
  return date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();
}
__name(isSameDay, "isSameDay");
function displayForCalendar(date) {
  return `${date.getHours()}h${date.getMinutes() || ""}`;
}
__name(displayForCalendar, "displayForCalendar");

// src/date/date.ts
var PARIS_TIMEZONE = {
  timeZone: "Europe/Paris"
};
var DISPLAY_HOUR = {
  ...PARIS_TIMEZONE,
  hour: "2-digit",
  minute: "2-digit"
};
var OverDate = class _OverDate {
  constructor(_date, _hour) {
    this._date = _date;
    this._hour = _hour;
  }
  static {
    __name(this, "OverDate");
  }
  static init({ date, hour }) {
    const hours = hour.toString().padStart(2, "0");
    const dateString = `${date}T${hours}:00+02:00`;
    return new _OverDate(new Date(dateString), hour);
  }
  static from(international) {
    const [hourWithPad] = Intl.DateTimeFormat("fr", DISPLAY_HOUR).format(international).split(":");
    const hour = +hourWithPad;
    const [day, month, year] = Intl.DateTimeFormat("fr", PARIS_TIMEZONE).format(international).split("/");
    const date = `${year}-${month}-${day}`;
    if (!isDateString(date) || !isHour(hour)) throw new Error();
    return _OverDate.init({ date, hour });
  }
  get date() {
    return this._date;
  }
  get dateString() {
    const date = formatLocalDate(this._date);
    if (!isDateString(date)) throw new Error("Date invalide");
    return date;
  }
  get hour() {
    return this._hour;
  }
  get period() {
    const start = this.date;
    const end = new Date(this.date.getTime() + ONE_HOUR_IN_MS);
    return Period.init({ start, end });
  }
  isIncludedBy(periods) {
    return periods.some((period) => Period.init(period).isIncluding(this.date));
  }
};
var DATE_TEMPORAL_PARTS = 3;
function isDateString(dateString) {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) return false;
  return dateString.split("-").length === DATE_TEMPORAL_PARTS;
}
__name(isDateString, "isDateString");
function isHour(hour) {
  return hour >= 0 && hour < 24;
}
__name(isHour, "isHour");

// src/edition/edition.ts
var BASE_EDITION_STARTS = /* @__PURE__ */ new Date("2023-09-01");
var BASE_EDITION = 49;
var Edition = class _Edition {
  static {
    __name(this, "Edition");
  }
  static get current() {
    return this.findEdition(/* @__PURE__ */ new Date());
  }
  static findEdition(date) {
    const dateTimestamp = date.getTime();
    const baseEditionTimestamp = BASE_EDITION_STARTS.getTime();
    const durationAfterBaseEdition = dateTimestamp - baseEditionTimestamp;
    const durationInYears = durationAfterBaseEdition / ONE_YEAR_IN_MS;
    const editionsAfterBaseEdition = Math.floor(durationInYears);
    return BASE_EDITION + editionsAfterBaseEdition;
  }
  static on(date) {
    return _Edition.findEdition(date);
  }
};
export {
  BASE_EDITION_STARTS,
  Duration,
  Edition,
  EndBeforeStart,
  ONE_DAY_IN_MS,
  ONE_HOUR_IN_MS,
  ONE_MINUTE_IN_MS,
  ONE_SECOND_IN_MS,
  ONE_YEAR_IN_MS,
  OverDate,
  Period,
  QUARTER_IN_MS,
  THIRTY_SECONDS_IN_MS,
  TWO_HOURS_IN_MS,
  computeTomorrowDate,
  displayForCalendar,
  formatDate,
  formatDateDayName,
  formatDateDayNumber,
  formatDateToHumanReadable,
  formatDateWithExplicitMonth,
  formatDateWithExplicitMonthAndDay,
  formatDateWithHoursAndMinutesOnly,
  formatDateWithMinutes,
  formatLocalDate,
  formatLocalDateTime,
  formatMonthWithYear,
  getHourDiff,
  isDateString,
  isHour,
  isSameDay,
  roundMinutes
};
