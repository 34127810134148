export default {
  default: () => import("/overbookd/apps/web/layouts/default.vue").then(m => m.default || m),
  header: () => import("/overbookd/apps/web/layouts/header/Header.vue").then(m => m.default || m),
  "header-profile": () => import("/overbookd/apps/web/layouts/header/HeaderProfile.vue").then(m => m.default || m),
  "header-logo": () => import("/overbookd/apps/web/layouts/header/Logo.vue").then(m => m.default || m),
  "navigation-desktop-side-nav": () => import("/overbookd/apps/web/layouts/navigation/DesktopSideNav.vue").then(m => m.default || m),
  "navigation-mobile-bottom-nav": () => import("/overbookd/apps/web/layouts/navigation/MobileBottomNav.vue").then(m => m.default || m),
  "navigation-side-nav-help-item-list": () => import("/overbookd/apps/web/layouts/navigation/SideNavHelpItemList.vue").then(m => m.default || m),
  "navigation-side-nav-page-item": () => import("/overbookd/apps/web/layouts/navigation/SideNavPageItem.vue").then(m => m.default || m),
  "navigation-side-nav-page-list": () => import("/overbookd/apps/web/layouts/navigation/SideNavPageList.vue").then(m => m.default || m),
  "navigation-side-nav-search-field": () => import("/overbookd/apps/web/layouts/navigation/SideNavSearchField.vue").then(m => m.default || m)
}