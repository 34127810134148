import revive_payload_client_1yTw2HUpNI from "/overbookd/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_2asx2aqcu6hgcjdom3rjm56sce/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_iZtepiLTX4 from "/overbookd/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_2asx2aqcu6hgcjdom3rjm56sce/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_pNltrT7EId from "/overbookd/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_2asx2aqcu6hgcjdom3rjm56sce/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_4TXHLn5bdb from "/overbookd/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_2asx2aqcu6hgcjdom3rjm56sce/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_ZU1Gug70dN from "/overbookd/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_2asx2aqcu6hgcjdom3rjm56sce/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_OFHj0Vb9Al from "/overbookd/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_2asx2aqcu6hgcjdom3rjm56sce/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_FqpgGirkb9 from "/overbookd/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_2asx2aqcu6hgcjdom3rjm56sce/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_qyMO5ioYgD from "/overbookd/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.21.3_typescript@5.6.2_vue@3.5.4_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/overbookd/apps/web/.nuxt/components.plugin.mjs";
import prefetch_client_nJXpfAcV3p from "/overbookd/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_2asx2aqcu6hgcjdom3rjm56sce/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chartjs_client_N7GULTnRpX from "/overbookd/apps/web/plugins/chartjs.client.ts";
import vue_html_secure_client_vi14lb5zJW from "/overbookd/apps/web/plugins/vue-html-secure.client.ts";
import vuetify_client_69rYAaPZnF from "/overbookd/apps/web/plugins/vuetify.client.ts";
export default [
  revive_payload_client_1yTw2HUpNI,
  unhead_iZtepiLTX4,
  router_pNltrT7EId,
  payload_client_4TXHLn5bdb,
  navigation_repaint_client_ZU1Gug70dN,
  check_outdated_build_client_OFHj0Vb9Al,
  chunk_reload_client_FqpgGirkb9,
  plugin_vue3_qyMO5ioYgD,
  components_plugin_KR1HBZs4kY,
  prefetch_client_nJXpfAcV3p,
  chartjs_client_N7GULTnRpX,
  vue_html_secure_client_vi14lb5zJW,
  vuetify_client_69rYAaPZnF
]