import { default as listAPdkf8KzrIMeta } from "/overbookd/apps/web/pages/charisma/events/list.vue?macro=true";
import { default as managesxtyaSgsgnMeta } from "/overbookd/apps/web/pages/charisma/events/manage.vue?macro=true";
import { default as periodscxSfU4F1VKMeta } from "/overbookd/apps/web/pages/charisma/periods.vue?macro=true";
import { default as configuration2dEaQRXeFnMeta } from "/overbookd/apps/web/pages/configuration.vue?macro=true";
import { default as contributions4fhyZxnSqeMeta } from "/overbookd/apps/web/pages/contributions.vue?macro=true";
import { default as _91id_93sdcHKUtHsgMeta } from "/overbookd/apps/web/pages/fa/[id].vue?macro=true";
import { default as securityYS8Di6DnlaMeta } from "/overbookd/apps/web/pages/fa/dashboard/security.vue?macro=true";
import { default as index8ilZ1QKZfBMeta } from "/overbookd/apps/web/pages/fa/index.vue?macro=true";
import { default as to_45publishUhFNWGhjzbMeta } from "/overbookd/apps/web/pages/fa/to-publish.vue?macro=true";
import { default as _91id_93C08J8NSNAfMeta } from "/overbookd/apps/web/pages/ft/[id].vue?macro=true";
import { default as indexGnvKFsedi1Meta } from "/overbookd/apps/web/pages/ft/index.vue?macro=true";
import { default as index1g8Z3dpszVMeta } from "/overbookd/apps/web/pages/index.vue?macro=true";
import { default as loginqC1IbfsvLXMeta } from "/overbookd/apps/web/pages/login.vue?macro=true";
import { default as _91borrowId_93GSEWlLA1StMeta } from "/overbookd/apps/web/pages/logistic/borrow/[borrowId].vue?macro=true";
import { default as indexlvaMMdUtLMMeta } from "/overbookd/apps/web/pages/logistic/borrow/index.vue?macro=true";
import { default as catalogLySoihzET8Meta } from "/overbookd/apps/web/pages/logistic/catalog.vue?macro=true";
import { default as dashboardSmuInTfGwHMeta } from "/overbookd/apps/web/pages/logistic/dashboard.vue?macro=true";
import { default as inventorymfIu0vyVwbMeta } from "/overbookd/apps/web/pages/logistic/inventory.vue?macro=true";
import { default as _91purchaseId_93dfzVgtIUSGMeta } from "/overbookd/apps/web/pages/logistic/purchase/[purchaseId].vue?macro=true";
import { default as indexxPClrflCQbMeta } from "/overbookd/apps/web/pages/logistic/purchase/index.vue?macro=true";
import { default as my_45personal_45account1lTn5ELdCAMeta } from "/overbookd/apps/web/pages/my-personal-account.vue?macro=true";
import { default as my_45profile60oRXGwXJDMeta } from "/overbookd/apps/web/pages/my-profile.vue?macro=true";
import { default as orga_45needFZGANruanCMeta } from "/overbookd/apps/web/pages/orga-need.vue?macro=true";
import { default as registervH6ryFC1oGMeta } from "/overbookd/apps/web/pages/register.vue?macro=true";
import { default as staff7bEWBK7Sr9Meta } from "/overbookd/apps/web/pages/registrations/staff.vue?macro=true";
import { default as _91token_93nCZK8PfWytMeta } from "/overbookd/apps/web/pages/reset/[token].vue?macro=true";
import { default as sgL8gyBRDxOwMeta } from "/overbookd/apps/web/pages/sg.vue?macro=true";
import { default as shared_45mealsysANZt1UpUMeta } from "/overbookd/apps/web/pages/shared-meals.vue?macro=true";
import { default as catalogFBE3UUK23HMeta } from "/overbookd/apps/web/pages/signa/catalog.vue?macro=true";
import { default as locatione7niAhLb0uMeta } from "/overbookd/apps/web/pages/signa/location.vue?macro=true";
import { default as stats3Jv8Af8hrfMeta } from "/overbookd/apps/web/pages/stats.vue?macro=true";
import { default as timelineDShQvn6JxdMeta } from "/overbookd/apps/web/pages/timeline.vue?macro=true";
import { default as transactionsuEzCbCjbPfMeta } from "/overbookd/apps/web/pages/transactions.vue?macro=true";
import { default as volunteersxgmHdUdwDCMeta } from "/overbookd/apps/web/pages/volunteers.vue?macro=true";
export default [
  {
    name: "charisma-events-list",
    path: "/charisma/events/list",
    component: () => import("/overbookd/apps/web/pages/charisma/events/list.vue").then(m => m.default || m)
  },
  {
    name: "charisma-events-manage",
    path: "/charisma/events/manage",
    component: () => import("/overbookd/apps/web/pages/charisma/events/manage.vue").then(m => m.default || m)
  },
  {
    name: "charisma-periods",
    path: "/charisma/periods",
    component: () => import("/overbookd/apps/web/pages/charisma/periods.vue").then(m => m.default || m)
  },
  {
    name: "configuration",
    path: "/configuration",
    component: () => import("/overbookd/apps/web/pages/configuration.vue").then(m => m.default || m)
  },
  {
    name: "contributions",
    path: "/contributions",
    component: () => import("/overbookd/apps/web/pages/contributions.vue").then(m => m.default || m)
  },
  {
    name: "fa-id",
    path: "/fa/:id()",
    component: () => import("/overbookd/apps/web/pages/fa/[id].vue").then(m => m.default || m)
  },
  {
    name: "fa-dashboard-security",
    path: "/fa/dashboard/security",
    component: () => import("/overbookd/apps/web/pages/fa/dashboard/security.vue").then(m => m.default || m)
  },
  {
    name: "fa",
    path: "/fa",
    component: () => import("/overbookd/apps/web/pages/fa/index.vue").then(m => m.default || m)
  },
  {
    name: "fa-to-publish",
    path: "/fa/to-publish",
    component: () => import("/overbookd/apps/web/pages/fa/to-publish.vue").then(m => m.default || m)
  },
  {
    name: "ft-id",
    path: "/ft/:id()",
    component: () => import("/overbookd/apps/web/pages/ft/[id].vue").then(m => m.default || m)
  },
  {
    name: "ft",
    path: "/ft",
    component: () => import("/overbookd/apps/web/pages/ft/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/overbookd/apps/web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginqC1IbfsvLXMeta || {},
    component: () => import("/overbookd/apps/web/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "logistic-borrow-borrowId",
    path: "/logistic/borrow/:borrowId()",
    component: () => import("/overbookd/apps/web/pages/logistic/borrow/[borrowId].vue").then(m => m.default || m)
  },
  {
    name: "logistic-borrow",
    path: "/logistic/borrow",
    component: () => import("/overbookd/apps/web/pages/logistic/borrow/index.vue").then(m => m.default || m)
  },
  {
    name: "logistic-catalog",
    path: "/logistic/catalog",
    component: () => import("/overbookd/apps/web/pages/logistic/catalog.vue").then(m => m.default || m)
  },
  {
    name: "logistic-dashboard",
    path: "/logistic/dashboard",
    component: () => import("/overbookd/apps/web/pages/logistic/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "logistic-inventory",
    path: "/logistic/inventory",
    component: () => import("/overbookd/apps/web/pages/logistic/inventory.vue").then(m => m.default || m)
  },
  {
    name: "logistic-purchase-purchaseId",
    path: "/logistic/purchase/:purchaseId()",
    component: () => import("/overbookd/apps/web/pages/logistic/purchase/[purchaseId].vue").then(m => m.default || m)
  },
  {
    name: "logistic-purchase",
    path: "/logistic/purchase",
    component: () => import("/overbookd/apps/web/pages/logistic/purchase/index.vue").then(m => m.default || m)
  },
  {
    name: "my-personal-account",
    path: "/my-personal-account",
    component: () => import("/overbookd/apps/web/pages/my-personal-account.vue").then(m => m.default || m)
  },
  {
    name: "my-profile",
    path: "/my-profile",
    component: () => import("/overbookd/apps/web/pages/my-profile.vue").then(m => m.default || m)
  },
  {
    name: "orga-need",
    path: "/orga-need",
    component: () => import("/overbookd/apps/web/pages/orga-need.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: registervH6ryFC1oGMeta || {},
    component: () => import("/overbookd/apps/web/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "registrations-staff",
    path: "/registrations/staff",
    component: () => import("/overbookd/apps/web/pages/registrations/staff.vue").then(m => m.default || m)
  },
  {
    name: "reset-token",
    path: "/reset/:token()",
    meta: _91token_93nCZK8PfWytMeta || {},
    component: () => import("/overbookd/apps/web/pages/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "sg",
    path: "/sg",
    component: () => import("/overbookd/apps/web/pages/sg.vue").then(m => m.default || m)
  },
  {
    name: "shared-meals",
    path: "/shared-meals",
    component: () => import("/overbookd/apps/web/pages/shared-meals.vue").then(m => m.default || m)
  },
  {
    name: "signa-catalog",
    path: "/signa/catalog",
    component: () => import("/overbookd/apps/web/pages/signa/catalog.vue").then(m => m.default || m)
  },
  {
    name: "signa-location",
    path: "/signa/location",
    component: () => import("/overbookd/apps/web/pages/signa/location.vue").then(m => m.default || m)
  },
  {
    name: "stats",
    path: "/stats",
    component: () => import("/overbookd/apps/web/pages/stats.vue").then(m => m.default || m)
  },
  {
    name: "timeline",
    path: "/timeline",
    component: () => import("/overbookd/apps/web/pages/timeline.vue").then(m => m.default || m)
  },
  {
    name: "transactions",
    path: "/transactions",
    component: () => import("/overbookd/apps/web/pages/transactions.vue").then(m => m.default || m)
  },
  {
    name: "volunteers",
    path: "/volunteers",
    component: () => import("/overbookd/apps/web/pages/volunteers.vue").then(m => m.default || m)
  }
]